
.App {
  text-align: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em auto;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 250px;
}

.input-field {
  width: 100%;
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.login-button {
  width: 100%;
  padding: 1em;
  margin-top: 1em;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.icpContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1em 0;
}
